import * as React from 'react'
import PropTypes from 'prop-types'

const Button = ({ name }) => {
  return (
    <button
      style={{ fontSize: '14px', borderWidth: '1.5px', letterSpacing: '3px' }}
      className="h-8 md:h-10 self-center font-light uppercase tracking-widest rounded-full border yellow-border border-yellow"
    >
      <span className="px-6 md:text-[16px]"> {name}</span>
    </button>
  )
}

Button.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
}

export default Button
