import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content from '../components/Content'
import MarkdownContent from '../components/MarkdownContent'


import Button from '../components/Button'
import entrySpeechMarks from '../img/entry-speech-marks.svg'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Helmet from 'react-helmet'

// eslint-disable-next-line
export const AboutPageTemplate = ({
  title,
  personOne,
  contentComponent,
  siteTitle,
}) => {
  const PageContent = contentComponent || Content

  return (
    <section>
      <div className="container flex flex-col">
        <Helmet>
          <title>{`${title} | ${siteTitle || 'Content Manager'}`}</title>
          <meta name="description" content={personOne.description} />
          <meta property="og:title" content={`${title} | ${siteTitle}`} />
          <meta property="og:description" content={personOne.description} />
        </Helmet>
        <h1 className="mt-10 mb-8 text-4xl purple-text text-purple font-bold font-playfair md:mt-20 md:text-5xl md:mb-18 lg:text-7xl lg:mb-24">
          {title}
        </h1>
        <div className="flex flex-col mb-8 md:flex-row">
          <div className="w-4/5 m-auto mb-4 md:w-full md:flex-1 md:m-0 max-w-md md:mr-8">
            <PreviewCompatibleImage imageInfo={personOne.profileImage} />
          </div>
          <div className="md:flex md:flex-col md:flex-1 lg:pr-20">
            <PageContent
              className="content text-center px-2 md:text-left"
              content={personOne.description}
            />
            <div className="hidden flex-col mt-8 pl-4 md:flex personal-quote md:pl-2">
              <p className="text-xl text-left">{personOne.personalQuote}</p>
            </div>
          </div>
          <div className="flex flex-col border-2 blue-border border-blue mt-8 p-4 md:hidden">
            <p className="font-medium text-center">{personOne.personalQuote}</p>
          </div>
        </div>
        <div className="flex flex-col mb-4 lg:ml-20">
          <h3 className="mb-4 text-center md:text-left md:mb-4">
            {personOne.referenceTitle}
          </h3>
          <img
            src={entrySpeechMarks}
            alt="Speech Mark"
            className="mb-2 md:mb-4"
            style={{ width: '49px', height: '35px' }}
          />
          <PageContent
            className="content italic text-center md:text-left reference-quote"
            content={personOne.referenceQuote}
          />
        </div>
        <div className="mb-8 mt-8 flex justify-center md:justify-start lg:ml-20 lg:mb-10 lg:mt-10">
          <Link className="w-fit self-center" to="/contact">
            <Button name="Get In Touch" />
          </Link>
        </div>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  personOne: PropTypes.object.isRequired,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        title={frontmatter.title}
        personOne={frontmatter.personOne}
        contentComponent={MarkdownContent}
        siteTitle={data.site.siteMetadata.title}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const AboutPageQuery = graphql`
  query AboutPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        personOne {
          profileImage {
            alt
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: NONE
                )
              }
            }
          }
          description
          personalQuote
          referenceTitle
          referenceQuote
        }
      }
    }
  }
`
